import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axiosConfig from "./config/axiosConfig";
import "./scss/App.scss";
import { Provider } from "react-redux";
import store from "./config/store";

axiosConfig.setRequests();

// function generateSessionId(length = 16) {
//     if(sessionStorage.getItem("session_id") && sessionStorage.getItem("session_id") !== "0" && sessionStorage.getItem("session_id") !== null) {
//         return sessionStorage.getItem("session_id");
//     } else {
//         const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//         let sessionId = '';
//         for (let i = 0; i < length; i++) {
//             const randomIndex = Math.floor(Math.random() * characters.length);
//             sessionId += characters[randomIndex];
//         }
//         sessionStorage.setItem("session_id", sessionId);
//         return sessionId;
//     }
// }
function generateSessionId(length = 16) {
    const sessionKey = "session_id";

    // Check if session_id is already set in sessionStorage
    let sessionId = sessionStorage.getItem(sessionKey);
    if (!sessionId || sessionId === "0") {
        sessionId = generateRandomId(length);
        sessionStorage.setItem(sessionKey, sessionId);

        // Sync the session ID across tabs using localStorage
        localStorage.setItem(sessionKey, sessionId);
    }

    return sessionId;
}

// Listen for session_id changes in other tabs (via localStorage)
window.addEventListener("storage", function(event) {
    if (event.key === "session_id" && event.newValue) {
        sessionStorage.setItem("session_id", event.newValue);
    }
});

function generateRandomId(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let sessionId = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        sessionId += characters[randomIndex];
    }
    return sessionId;
}

global.ipaddress = generateSessionId();


// fetch('https://api.ipify.org?format=json')
//     .then(response => response.json())
//     .then(data => {
//         global.ipaddress = data.ip;
//         console.log('Your Public IP Address:', data.ip);
//     })
//     .catch(error => {
//         console.error('Error fetching IP:', error);
//     });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
