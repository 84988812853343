import React, {useEffect} from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import routes from "./routes_mapper";
import NotFound from "./NotFound";
import LoginContainer from "./login/LoginContainer";
import PrivateRoute from "./routes/PrivateRoute";
import AppRoutes from "./routes/AppRoutes";
import TagManager from 'react-gtm-module';
// import SignupContainer from "./login/SignupContainer";
// import ForgotPassword from "./login/ForgotPassword";
// import ResetPassword from "./login/ResetPassword";
// import VerifyUser from "./login/VerifyUser";

const App = () => {
    useEffect(() => {
        const tagManagerArgs = {
            gtmId: 'GTM-N9VJLP8'
        };
        TagManager.initialize(tagManagerArgs);

    }, []);
    const tagManagerArgs = {
        dataLayer: {
            userId: '001',
            "userProject": 'project',
            "page": 'home',
            "event": 'ChatbotEvent',
            'event_category': 'Chatbot Interaction',
            'event_label': 'Chat Bot Open',
            'value': 1
        },
        dataLayerName: 'ChatbotEvent'
    }
    TagManager.dataLayer(tagManagerArgs)
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.LOGIN} element={<LoginContainer />} />
        {/* <Route path={routes.SIGNUP} element={<SignupContainer />} /> */}
        {/* <Route path={routes.FORGOTPASSWORD} element={<ForgotPassword />} /> */}
        {/* <Route path={routes.EMAIL_VERIFY} element={<VerifyUser />} /> */}
        {/* <Route path={routes.RESET_PASSWORD} element={<ResetPassword />} /> */}
        <Route
          path={`${routes.ROOT}*`}
          element={<PrivateRoute component={AppRoutes} />}
        />
        <Route path={"*"} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
